<template>
  <div>
    <financial-adjustment-details-sidebar />
    <!-- Totalizer -->
    <financial-adjustments-totalizer
      :saveAction="search"
      :totalizer="totalizer"
    />
    <b-card no-body class="mb-0">
      <!-- Filters -->
      <financial-adjustments-list-filters ref="filters" />
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mb-0">Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label class="mb-0">itens</label>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="outline-secondary"
                @click.prevent="clearSearch"
                class="mr-1"
                :disabled="loading"
              >
                Limpar filtros
              </b-button>
              <b-button
                variant="primary"
                @click.prevent="search(1)"
                :disabled="loading"
              >
                <b-spinner v-if="loading" small />
                <feather-icon v-else icon="SearchIcon" class="mr-50" />
                <span>
                  {{ loading ? "Aguarde..." : "Pesquisar" }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Não foram encontrados lançamentos para esta busca"
        :busy="loading"
        :no-local-sorting="true"
        :sort-by="sortField"
        @sort-changed="sort"
      >
        <!-- Column: Nome do lançamento -->
        <template #cell(name)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.name
            }}</span>
          </div>
        </template>

        <!-- Column: Serviços/ Colaboradores/ Comercial -->
        <template #cell(subject)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.consultant_name || data.item.department_name
            }}</span>
          </div>
        </template>

        <!-- Column: Valor -->
        <template #cell(value)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.value | toCurrency
            }}</span>
          </div>
        </template>

         <!-- Column: Valor -->
         <template #cell(installment)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.installment
            }}</span>
          </div>
        </template>

        <!-- Column: Data da solicitação -->
        <template #cell(launch_date)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.date | dateToStr }}</span>
          </div>
        </template>

        <!-- Column: Tipo do lançamento -->
        <template #cell(credit_type)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{ data.item.credit_type }}</span>
          </div>
        </template>

        <!-- Column: Motivo do lançamento -->
        <template #cell(adjustment_reason)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.adjustment_reason
            }}</span>
          </div>
        </template>

        <!-- Column: Data de faturamento -->
        <template #cell(billing_date)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.billing_date | dateTime
            }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button-group>
            <b-button
              :id="`details-adjustment` + data.item.id"
              class="btn-icon"
              variant="flat"
              style="padding: 0; color: white"
              @click="openDetails(data.item.id)"
            >
              <feather-icon class="text-body" icon="FileTextIcon" />
            </b-button>
            <b-tooltip
              :target="`details-adjustment` + data.item.id"
              triggers="hover"
            >
              Detalhes do lançamento
            </b-tooltip>
            <template v-if="!data.item.invoice_id">
              <b-button
                v-if="canUpdate"
                :id="`edit-adjustment` + data.item.id"
                class="btn-icon mx-1"
                variant="flat"
                style="padding: 0; color: white"
                @click="editAdjustment(data.item.id)"
              >
                <feather-icon class="text-body" icon="EditIcon" />
              </b-button>
              <b-tooltip
                v-if="canUpdate"
                :target="`edit-adjustment` + data.item.id"
                triggers="hover"
              >
                Alterar lançamento
              </b-tooltip>
              <b-button
                v-if="canDelete"
                :id="`delete-adjustment` + data.item.id"
                class="btn-icon"
                variant="flat"
                style="padding: 0; color: white"
                @click="deleteAdjustment(data.item.id)"
              >
                <feather-icon class="text-body" icon="TrashIcon" />
              </b-button>
              <b-tooltip
                v-if="canDelete"
                :target="`delete-adjustment` + data.item.id"
                triggers="hover"
              >
                Remover lançamento
              </b-tooltip>
            </template>
          </b-button-group>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
  BButtonGroup,
  BTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import FinancialAdjustmentsListFilters from "../components/FinancialAdjustmentsListFilters";
import FinancialAdjustmentsTotalizer from "../components/FinancialAdjustmentsTotalizer";
import FinancialAdjustmentDetailsSidebar from "../components/FinancialAdjustmentDetailsSidebar";
import { getRangeDates } from "@/helpers/date_picker";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import useAppConfig from "@core/app-config/useAppConfig";
import {
  CREATE_ACTION,
  UPDATE_ACTION,
  DELETE_ACTION,
  FINANCIAL_ADJUSTMENTS,
} from "@/constants/resources";


export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BButtonGroup,
    BTooltip,
    vSelect,
    FinancialAdjustmentsListFilters,
    FinancialAdjustmentsTotalizer,
    FinancialAdjustmentDetailsSidebar,
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      loading: false,
      tableColumns: [
        { key: "name", label: "Descrição", sortable: true },
        { key: "subject", label: "Interessado", sortable: true },
        { key: "value", label: "Valor", sortable: true },
        { key: "installment", label: "Parcela", sortable: true },
        { key: "launch_date", label: "Data da solicitação", sortable: true },
        { key: "credit_type", label: "Tipo do lançamento", sortable: true },
        {
          key: "adjustment_reason",
          label: "Motivo do lançamento",
          sortable: true,
        },
        { key: "billing_date", label: "Data de faturamento", sortable: true },
        { key: "actions", label: "Ações" },
      ],
      order: undefined,
      sortField: null,
      currentPage: 1,
      itemsPerPage: 1,
    };
  },
  computed: {
    ...mapGetters({
      searchResult: types.FINANCIAL_ADJUSTMENTS_SEARCH_RESULT,
      totalizer: types.FINANCIAL_ADJUSTMENT_TOTALIZER,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
    }),
    canCreate() {
      return this.$can(CREATE_ACTION, FINANCIAL_ADJUSTMENTS);
    },
    canUpdate() {
      return this.$can(UPDATE_ACTION, FINANCIAL_ADJUSTMENTS);
    },
    canDelete() {
      return this.$can(DELETE_ACTION, FINANCIAL_ADJUSTMENTS);
    },
    items: function () {
      return this.searchResult?.data || [];
    },
    totalItems: function () {
      return this.searchResult?.total || 0;
    },
    pageItemsFrom: function () {
      return this.searchResult?.from || 0;
    },
    pageItemsTo: function () {
      return this.searchResult?.to || 0;
    },
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.search();
  },
  methods: {
    ...mapActions({
      searchFinancialAdjustments: types.SEARCH_FINANCIAL_ADJUSTMENTS,
      getTotalizer: types.GET_FINANCIAL_ADJUSTMENT_TOTALIZER,
      openDetailsSidebar: types.OPEN_FINANCIAL_ADJUSTMENT_DETAILS_SIDEBAR,
      deleteFinancialAdjustment: types.DELETE_FINANCIAL_ADJUSTMENT,
      openEditFinancialAdjustmentSidebar:
        types.OPEN_SAVE_FINANCIAL_ADJUSTMENT_SIDEBAR,
    }),
    search(currentPage) {
      this.loading = true;
      this.currentPage = Number.isInteger(currentPage) ? currentPage : 1;
      const {
        creditType,
        consultant,
        employee,
        department,
        adjustmentReason,
        rangeDate,
      } = this.$refs.filters;
      const date = getRangeDates(rangeDate);
      this.searchFinancialAdjustments({
        credit_type: creditType,
        consultant,
        employee,
        department,
        adjustment_reason: adjustmentReason,
        launch_date_start: date.start,
        launch_date_end: date.end,
        order: this.order,
        limit: this.itemsPerPage,
        currentPage: this.currentPage
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar os lançamentos. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
      this.getTotalizer({
        credit_type: creditType,
        consultant,
        employee,
        department,
        adjustment_reason: adjustmentReason,
        launch_date_start: date.start,
        launch_date_end: date.end,
      }).catch((error) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao recuperar os dados do totalizador. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    },
    sort(event) {
      //clear in third click
      if (!event.sortDesc && this.sortField == event.sortBy) {
        this.order = undefined;
        this.sortField = null;
      } else {
        var direction = event.sortDesc ? " DESC" : " ASC";
        this.order = event.sortBy + direction;
        this.sortField = event.sortBy;
      }
      this.search();
    },
    openDetails(id) {
      this.openDetailsSidebar({ id });
    },
    deleteAdjustment(id) {
      this.$swal({
        title: "Confirmação",
        text: `Deseja realmente remover? A operação não poderá ser desfeita.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      }).then((result) => {
        if (result.value) {
          this.deleteFinancialAdjustment(id)
            .then((response) => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso!",
                  text: "Removido com sucesso.",
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
              this.search(this.currentPage);
            })
            .catch((err) => {
              if (err.response.status == 405) {
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: "Oops!",
                    text:
                      "Ocorreu um erro ao remover. " +
                      err.response.data.message,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              } else {
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: "Oops!",
                    text: "Ocorreu um erro ao remover. Entre em contato com o setor de TI.",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              }
            });
        }
      });
    },
    editAdjustment(id) {
      this.openEditFinancialAdjustmentSidebar({ id, saveAction: this.search });
    },
    clearSearch() {
      this.$refs.filters.creditType = undefined;
      this.$refs.filters.consultant = undefined;
      this.$refs.filters.employee = undefined;
      this.$refs.filters.department = undefined;
      this.$refs.filters.adjustmentReason = undefined;
      this.$refs.filters.rangeDate = undefined;
      this.search(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
